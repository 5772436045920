import { ArrowDownIcon } from '@dsch/icons';
import styled, { css } from 'styled-components';

import { Spinner } from '../../components/Toolkit/Spinner/Spinner';

export const Page = styled.div`
  background: #ffffff;
  max-width: 100%;
  overflow: hidden;
`;

export const LowerPage = styled.div`
  position: relative;
  z-index: 10;
`;

export const SearchHeroWrapper = styled.div`
  *:focus:not(input) {
    outline: 1px dotted rgba(0, 0, 0, 0.5);
  }
  position: relative;
  z-index: 15;
  background-color: ${({ theme }) => theme.color.GREY_DARKER};
  background-size: cover;
  padding: 90px 8px 142px;
  margin-top: -87px;
  margin-bottom: 146px;

  height: 550px;

  @media only screen and (min-width: 640px) {
    padding: 130px 24px 188px;
    min-height: 660px;
  }

  @media only screen and (min-width: 861px) {
    margin-bottom: 180px;
  }
`;

export const CenteredSpinner = styled(Spinner)`
  position: absolute;
  right: 48%;
  top: 280px;
  width: 60px;
`;

export const SearchHeroInner = styled.div`
  z-index: 10;
  position: relative;
  max-width: 860px;
  margin: 0 auto;
`;

export const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  picture {
    img,
    source {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
`;

export const HomePageTagLine = styled.h1`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 41px;
  text-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.68);
  color: #ffffff;
  padding: 0 30px;
  margin-bottom: 16px;

  @media only screen and (max-width: 321px) {
    padding: 0 20px;
  }

  @media only screen and (min-width: 420px) {
    font-size: 28px;
    margin-bottom: 16px;
    padding: 0 62px;
  }

  @media only screen and (min-width: 525px) {
    padding: 0;
  }

  @media only screen and (min-width: 640px) {
    font-size: 44px;
    line-height: 36px;
    margin-bottom: 28px;
  }
`;

export const StyledArrowCSS = css`
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 15vh;
  color: #ffffff;
  width: 24px;
  height: 24px;
  @media only screen and (min-width: 861px) {
    display: none;
  }
`;

export const StyledArrowDownIcon = styled(ArrowDownIcon)`
  ${StyledArrowCSS};
`;

export const Anchor = styled.a`
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 44px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: -8px;
  }

  @media only screen and (min-width: 861px) {
    display: none;
  }
`;

export const LowerContent = styled.div`
  padding: 40px 16px 0;
  max-width: 1040px;
  margin: 0 auto;
  @media only screen and (min-width: 640px) {
    padding: 0 0 29px 0;
  }
`;

export const StyledH3 = styled.h3`
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 16px;

  @media only screen and (min-width: 800px) {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 26px;
  }
`;

export const StyledAdSection = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -125px;
  min-height: 260px;
`;

export const LoadingOverlay = styled.div`
  ${({ isLoading }: { isLoading: boolean }) => isLoading && 'opacity: 0.15'};
`;
