import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGE_BREAK_POINT_640PX } from '../../../types/breakpoints';
import { ThemeTypes } from '../../../types/theme';

export type SectionType = {
  url: string;
  name: string;
  isExternalLink?: boolean;
};

export type SectionPickerProps = {
  onChange: (section: SectionType) => void;
  sections: SectionType[];
  activeSection?: number;
};

export type StyledSectionProp = {
  isSelected: boolean;
  onClick: (section: SectionType) => void;
};

const SectionWrapper = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  ${({ theme }) => theme.fontSize.S14};
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_640PX}) {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
    ${({ theme }) => theme.fontSize.T18};
  }

  &:focus {
    outline: 0;
  }
`;

const Section = styled.li<StyledSectionProp>`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  text-align: center;
  text-decoration: none;
  padding: ${({ theme }) =>
    `${theme.spacing.S2} ${theme.spacing.S8} ${theme.spacing.S8} ${theme.spacing.S8}`};
  margin: 0 0px 10px;
  cursor: pointer;
  &:hover {
    border-bottom: ${({ theme }) => `3px solid ${theme.color.PRIMARY}`};
    color: ${({ theme }) => theme.color.PRIMARY};
  }

  ${({ isSelected, theme }: { isSelected: boolean; theme: ThemeTypes }) =>
    isSelected
      ? `border-bottom: 3px solid ${theme.color.PRIMARY}; color: ${theme.color.PRIMARY};`
      : ''} display: block;

  @media only screen and (min-width: 420px) {
    padding: 2px 15px 8px;
    margin: 0 5px 10px;
  }
`;

const SectionLink = Section.withComponent('a');

export const SectionPicker = ({
  sections,
  activeSection,
  onChange,
}: SectionPickerProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    activeSection || 0,
  );

  const onClick = (section: SectionType, index: number) => {
    setSelectedIndex(index);
    onChange(section);
  };

  return (
    <SectionWrapper>
      {sections.map((section, index) =>
        section?.isExternalLink ? (
          <SectionLink
            key={index}
            href={section.url}
            isSelected={index === selectedIndex}
            onClick={() => onClick(section, index)}
            data-testid={`section-link-${section.name}`}
          >
            {section.name}
          </SectionLink>
        ) : (
          <Section
            key={index}
            role="menuitem"
            tabIndex={0}
            isSelected={index === selectedIndex}
            onClick={() => onClick(section, index)}
            onKeyPress={() => onClick(section, index)}
          >
            {section.name}
          </Section>
        ),
      )}
    </SectionWrapper>
  );
};
