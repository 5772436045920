import React from 'react';

import styled from 'styled-components';

import {
  NewButtonSizes,
  NewButtonTypes,
} from '../../../components/Toolkit/NewButton/NewButton';
import { Panel } from '../../../components/Toolkit/Panel/Panel';
import { CDN_URL_STATIC_DIRECTORY } from '../../../helpers/utils';
import {
  PAGE_BREAK_POINT_1004PX,
  PAGE_BREAK_POINT_1080PX,
  PAGE_BREAK_POINT_640PX,
  PAGE_BREAK_POINT_800PX,
} from '../../../types/breakpoints';

export type NewsPanelProps = {
  title: string;
  subtitle: string;
  ctaLink: string;
  image?: string;
};

const newsPanelsData: NewsPanelProps[] = [
  {
    title: 'Residential Zoned Land Tax (RZLT)',
    subtitle: 'The Residential Zoned Land Tax is a tax to be applied at a...',
    ctaLink:
      'https://www.knightfrank.ie/residential-zoned-land-tax?utm_source=Daft&utm_medium=Daft+Commercial+Homepage&utm_campaign=Backlinks+for+Knight+Frank+-+RPZs',
  },
  {
    title: 'Dublin Office Market Q1 2023',
    subtitle: 'The length and complexity of the planning process is...',
    ctaLink:
      'https://www.knightfrank.ie/research/dublin-office-market-q1-2023?utm_source=Daft&utm_medium=Daft+Commercial+Homepage&utm_campaign=Backlinks+for+Knight+Frank+-+Office+Market+Q1',
  },
];

const PanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 68px;

  @media only screen and (min-width: ${PAGE_BREAK_POINT_640PX}) {
    flex-wrap: nowrap;
    padding: 0 16px 82px;
  }
  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    padding: 0 0 82px;
  }
`;
const Title = styled.h3`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.T18};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  line-height: ${({ theme }) => theme.spacing.M24};
  margin-bottom: ${({ theme }) => theme.spacing.M24};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ theme }) => theme.fontSize.T20};
  }

  @media only screen and (min-width: 768px) and (max-width: ${PAGE_BREAK_POINT_1004PX}) {
    margin-left: ${({ theme }) => theme.spacing.M24};
  }
`;

const NewsPanels = () => {
  return (
    <>
      <Title>Commercial News</Title>
      <PanelWrapper>
        {newsPanelsData.map((panel, index) => (
          <Panel
            key={index}
            id={`news-panel-${index}`}
            border={true}
            themeName={'DEFAULT_VERTICAL_MEDIUM'}
            title={panel.title}
            subtitle={panel.subtitle}
            close={false}
            cta={'LINK'}
            background={false}
            ctaLink={panel.ctaLink}
            ctaText={'Learn More'}
            onClick={() => null}
            buttonSize={NewButtonSizes.SMALL}
            buttonType={NewButtonTypes.SECONDARY}
            borderRadius={true}
            image={`${CDN_URL_STATIC_DIRECTORY}/static/images/news_alert.svg`}
            opensInNewTab={true}
          />
        ))}
      </PanelWrapper>
    </>
  );
};
export { NewsPanels };
