import React, { useEffect, useState } from 'react';

import { SwitchIcon } from '@dsch/icons';
import { DFPSlotsProvider, DFPManager } from '@dsch/react-dfp';

import { PageWrapper } from '../../components/Styles/styles';
import { TabsPanels } from '../../components/Toolkit/TabsPanels/TabsPanels';
import { StudentAccommodationSearchHeroSections } from '../../config';
import ErrorBoundary from '../../helpers/components/ErrorBoundary';
import SmartLocalStorage from '../../helpers/storage/SmartLocalStorage';
import { stripLeadingForwardSlash } from '../../helpers/url/urlUtils';
import {
  BASE_URL,
  CDN_URL_STATIC_DIRECTORY,
  DFP_NETWORK_ID,
} from '../../helpers/utils';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { GoogleAnalyticsService } from '../../services/Analytics/AnalyticsService';
import SearchSEOParams from '../../services/Url/SearchSEOParams';

import { DFPWrapper } from './homepage-components/DFPWrapper';
import { LastSearchType } from './homepage-components/LastSearch/LastSearch.types';
import { NewsPanels } from './homepage-components/NewsPanels';
import { PopularAreas } from './homepage-components/PopularAreas';
import { QuickLinks } from './homepage-components/QuickLinks';
import { SectionedAutocomplete } from './homepage-components/SectionedAutocomplete';
import {
  HeroSEOContent,
  AdditionalSEO,
} from './homepage-components/SEOContent';
import * as S from './HomePage.styled';
import { HeroSection, HomepageProps } from './HomePage.types';
import routes from '../../server/routes';
import { AreaTag } from '../../types';
import { setDFPProps } from '../Toolkit/DFPContainer/DFP';

const { Router, Link } = routes;

const getSearchPath = (
  searchHeroSections: HeroSection[],
  currentPath: string,
): string => {
  const searchPath = searchHeroSections.find(
    ({ pageRoute }: HeroSection) => pageRoute === currentPath,
  );

  if (searchPath) {
    return searchPath.url;
  }
  //TO DO: Find better solution Bojana!
  if (currentPath === '/commercial-property') {
    return '/commercial-properties-for-rent';
  }
  if (currentPath === '/student-accommodation') {
    return '/student-accommodation-for-rent';
  }
  return currentPath;
};

const getActiveSection = (
  searchHeroSections: HeroSection[],
  searchPath: string,
): HeroSection => {
  const currentSection = searchHeroSections.find(
    ({ url }: HeroSection) => url === searchPath,
  );

  if (currentSection) {
    return currentSection;
  }

  return searchHeroSections[0];
};

const googleAnalyticsService: GoogleAnalyticsService =
  new GoogleAnalyticsService();

const HomePage = (props: HomepageProps) => {
  const {
    backgroundImage,
    category,
    children,
    popularAreas,
    propertyTools,
    reactDFPAdsReadyToLoad,
    searchHeroSections,
    SEOContent,
    tagline,
  } = props;

  const [searchPath, setSearchPath] = useState(
    getSearchPath(searchHeroSections, props.currentPath),
  );

  useEffect(() => {
    const pageType = getPageTypeForDataLayer(searchPath);
    DFPManager.setTargetingArguments({ pageType: [pageType] });

    if (typeof window !== 'undefined') {
      setLastSearch(SmartLocalStorage.get('lastSearchQuery'));
    } else {
      throw new Error('Method can only be used on the client');
    }
  }, []);

  const IS_COMMERCIAL = Boolean(category === 'COMMERCIAL');
  const IS_STUDENT_ACCOMMODATION = Boolean(
    category === 'STUDENT_ACCOMMODATION',
  );

  const [isLoading, setIsLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState<LastSearchType | null>(null);

  const currentSection: HeroSection = IS_STUDENT_ACCOMMODATION
    ? StudentAccommodationSearchHeroSections[0]
    : getActiveSection(searchHeroSections, searchPath);
  const currentPath = IS_STUDENT_ACCOMMODATION ? '/colleges' : '';
  const placeholderText = IS_STUDENT_ACCOMMODATION
    ? 'College or University'
    : 'County, City, Town or Area';
  const disablePropertyCount = IS_STUDENT_ACCOMMODATION;

  const hasPopularAreas =
    category === 'RESIDENTIAL' &&
    popularAreas &&
    !currentSection?.isExternalLink;

  const updatePath = (
    searchPath: string,
    pageRoute?: string,
    isExternalLink?: boolean,
  ) => {
    const pageType = getPageTypeForDataLayer(searchPath);
    googleAnalyticsService.pushToDataLayer({
      pageType: pageType,
    });

    setSearchPath(searchPath);

    if (pageRoute && !isExternalLink) {
      Router.pushRoute(pageRoute);
    }

    DFPManager.setTargetingArguments({ pageType: [pageType] });

    DFPManager.reload();
  };

  const search = (selectedItems: AreaTag[]) => {
    // re-focus to the top of the screen before the user lands on the search page
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    const location = selectedItems.map((item) => item.displayValue);
    SearchSEOParams.updateCurrentValue('location', location);
    const routeName = stripLeadingForwardSlash(searchPath);
    document.body.focus();
    Router.pushRoute(routeName, SearchSEOParams.makeReconciledQueryObject({}));
    setIsLoading(true);
  };

  const getPageTypeForDataLayer = (searchPath: string) => {
    const pageTypes: { [key: string]: string } = {
      '/property-for-sale': 'homepage-buy',
      '/property-for-rent': 'homepage-rent',
      '/sharing': 'homepage-share',
      '/new-homes-for-sale': 'homepage-newhomes',
      '/commercial-properties-for-rent': 'homepage-commercial',
      '/commercial-properties-for-sale': 'homepage-commercial',
      '/student-accommodation-for-rent': 'homepage-student',
      '/student-accommodation-to-share': 'homepage-student',
      'https://www.sell.daft.ie/': 'https://www.sell.daft.ie/',
    };

    return pageTypes[searchPath];
  };

  const HeroIcon = IS_COMMERCIAL ? (
    <Link route="homepage" passHref legacyBehavior>
      <S.Anchor data-testid="onpage-residential">
        Residential <SwitchIcon />
      </S.Anchor>
    </Link>
  ) : (
    IS_STUDENT_ACCOMMODATION && <S.StyledArrowDownIcon />
  );

  const imageCategoryDesktop =
    category === 'COMMERCIAL'
      ? `${CDN_URL_STATIC_DIRECTORY}/static/images/backgrounds/homepage-hero-commercial-desktop`
      : category === 'STUDENT_ACCOMMODATION'
        ? `${CDN_URL_STATIC_DIRECTORY}/static/images/backgrounds/homepage-hero-student-accommodation-desktop`
        : `${CDN_URL_STATIC_DIRECTORY}/${backgroundImage?.desktop}`;

  const imageCategoryMobile =
    category === 'COMMERCIAL'
      ? `${CDN_URL_STATIC_DIRECTORY}/static/images/backgrounds/homepage-hero-commercial-mobile`
      : category === 'STUDENT_ACCOMMODATION'
        ? `${CDN_URL_STATIC_DIRECTORY}/static/images/backgrounds/homepage-hero-student-accommodation-mobile`
        : `${CDN_URL_STATIC_DIRECTORY}/${backgroundImage?.mobile}`;

  return (
    <S.Page>
      <DFPSlotsProvider
        disableInitialLoad
        {...setDFPProps({
          dfpNetworkId: DFP_NETWORK_ID,
          collapseEmptyDivs: true,
        })}
      >
        <DefaultLayout
          headerTheme="ALTERNATIVE"
          pageVariant={IS_COMMERCIAL ? 'COMMERCIAL' : undefined}
          category={category}
          BASE_URL={BASE_URL}
          CDN_URL_STATIC_DIRECTORY={CDN_URL_STATIC_DIRECTORY}
          routes={routes}
        >
          {isLoading && <S.CenteredSpinner data-testid="loading-spinner" />}
          <S.LoadingOverlay isLoading={isLoading}>
            <S.SearchHeroWrapper>
              <PageWrapper>
                <S.HeroImage>
                  <picture>
                    <source
                      height={'100%'}
                      media={`(min-width: 640px)`}
                      srcSet={`${imageCategoryDesktop}.webp`}
                      type="image/webp"
                    />
                    <source
                      media={`(max-width: 640px)`}
                      srcSet={`${imageCategoryMobile}.webp`}
                      type="image/webp"
                    />
                    <img
                      src={`${imageCategoryDesktop}.jpg`}
                      alt="homepage image"
                    />
                  </picture>
                </S.HeroImage>
                <S.SearchHeroInner>
                  <S.HomePageTagLine data-testid="homepage-tagline">
                    {tagline}
                  </S.HomePageTagLine>
                  <ErrorBoundary
                    renderFallback={() => (
                      <p>Section Autocomplete error, please refresh</p>
                    )}
                  >
                    <SectionedAutocomplete
                      currentSection={currentSection}
                      sections={searchHeroSections}
                      search={search}
                      handleSectionChanged={updatePath}
                      currentPath={currentPath}
                      placeholderText={placeholderText}
                      disablePropertyCount={disablePropertyCount}
                      lastSearch={lastSearch}
                    />
                  </ErrorBoundary>
                </S.SearchHeroInner>
                <S.StyledAdSection>
                  {SEOContent && SEOContent[currentSection.name] && (
                    <>
                      <HeroSEOContent
                        SEOContent={SEOContent[currentSection.name]}
                      />
                      <AdditionalSEO
                        SEOContent={SEOContent[currentSection.name]}
                      />
                    </>
                  )}
                  {reactDFPAdsReadyToLoad && <DFPWrapper />}
                </S.StyledAdSection>
                {HeroIcon}
              </PageWrapper>
            </S.SearchHeroWrapper>

            <S.LowerPage>
              {category === 'RESIDENTIAL' && (
                <S.LowerContent>
                  <QuickLinks title="Browse by section" category={category} />
                </S.LowerContent>
              )}

              {category === 'COMMERCIAL' && (
                <>
                  <S.LowerContent>
                    <QuickLinks
                      title="Browse by type"
                      category={category}
                      activeSection={currentSection.name}
                    />
                  </S.LowerContent>
                  <S.LowerContent>
                    <NewsPanels />
                  </S.LowerContent>
                </>
              )}

              {propertyTools && (
                <ErrorBoundary
                  renderFallback={() => (
                    <p>Property tools error, please refresh</p>
                  )}
                >
                  <TabsPanels
                    title="Property Tools"
                    searchPath={searchPath}
                    activeTab={0}
                    tabsPanels={propertyTools}
                  />
                </ErrorBoundary>
              )}
              {hasPopularAreas && (
                <ErrorBoundary
                  renderFallback={() => (
                    <p>Popular Areas error, please refresh</p>
                  )}
                >
                  <PopularAreas
                    searchPath={searchPath}
                    activeTab={0}
                    currentSectionName={currentSection.name}
                    popularAreas={popularAreas}
                  />
                </ErrorBoundary>
              )}
            </S.LowerPage>

            {children}
          </S.LoadingOverlay>
        </DefaultLayout>
      </DFPSlotsProvider>
    </S.Page>
  );
};

export default HomePage;
